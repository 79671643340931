<template>
  <div class="quintessence-wrap">
    <van-nav-bar
      :title="title"
      @click-left="clickNavBack"
      left-arrow
      id="reset"
      :border="false"
    ></van-nav-bar>
    <div id="cc_player" class="cc_player fixed">
      <div v-if="iscover">
        <img src="@/assets/vip/video-subject-1.png" v-if="subject === '1'" alt="">
        <img src="@/assets/vip/video-subject-4.png" v-if="subject === '4'" alt="">
      </div>
    </div>
    <div class="quin-course">
      <div class="course-list">课程列表&xrarr;</div>
      <div class="course-wrap">
        <div class="course-box" :class="isactive==i ?'allcolor':''" v-for="(video,i) in listvideo" :key="i" @click="courseVideo(video,i)">
          <div class="course-text">第{{ i + 1 }}课</div>
          <div class="course-name">{{ video.videoName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVipVideo } from '../../api/user';
export default {
	name: 'vipquintessence',
	data() {
		return {
			title: "精髓课",
			iscover: true,
			listvideo: {},
			isactive: -1,
			activeVideo: 0,
			nextActiveVideo: 0,
			playedVideo: {}, // 当前观看视频
			nextWatchVideo: {}, // 记录上次观看的下一个
			player: null,
		};
	},
	components: {},
	created() {
		this.subject = this.$route.query.subject;
		this.categoryId = this.$route.query.categoryId;
		this.bindCCEvent();
		this.getVipVideo();
	},
	computed: {
		token() {
			return JSON.parse(localStorage.getItem('loginInTKfor_APP02')).token
		}
	},
	mounted() {
	},
	methods: {
		// 获取易考精选题
		getVipVideo() {
			getVipVideo(
				this.categoryId,
				this.subject
			).then((res) => {
				this.listvideo = res;
				this.loadvideo();
			}).catch(() => {
			})
		},
		loadvideo() {
			// let lastWatchVideo = {}, nextWatchVideo = {};
			let lastWatchVideo = {};
			this.listvideo.map((video,i) => {
				console.log(video.watchedFlag);
				if (video && video.watchedFlag === '1') {
					this.activeVideo = i;
					lastWatchVideo = video;
					// 记录下一个
					// if (i + 1 <= this.listvideo.length - 1) {
					// 	this.nextActiveVideo = i + 1;
					// 	nextWatchVideo = this.listvideo[i + 1]
					// 	console.log(nextWatchVideo,this.listvideo[i + 1]);
					// } else { // 全部已学习
					// 	nextWatchVideo = {done: true};
					// 	console.log(nextWatchVideo);
					// }
				}
			})
			this.playedVideo = lastWatchVideo;
			if (lastWatchVideo.videoCcid) {
				// this.nextWatchVideo = nextWatchVideo;
				this.courseVideo(this.playedVideo,this.activeVideo);
			} else {
				this.courseVideo(this.listvideo[0],0);
			}
		},
		// 点击回退
		clickNavBack() {
			this.$router.go(-1);
		},
		// 绑定 cc 全局事件
		bindCCEvent () {
			window.onCCH5PlayerLoaded = this.onCCH5PlayerLoaded
			window.on_CCH5player_play = this.on_CCH5player_play
			window.on_CCH5player_pause = this.on_CCH5player_pause
			window.on_CCH5player_ended = this.on_CCH5player_ended
			window.on_CCH5player_ready = this.onPlayerReady
			window.on_player_volumechange = this.onPlayerVolumeChange
		},
		courseVideo(video,i) {
			this.isactive = i;
			this.loadCCVideo(video.videoCcid)
		},
		/** 加载CC视频 **/
		loadCCVideo (data) {
			this.reviewer = document.getElementById('cc_player');
			this.player = window.createCCH5Player({
				vid: data,
				siteid: 'D9CDA78D56C216BD',
				autoStart: 'true',
				showRateBtn: 'false',
				banDrag: 'false',
				playtype: 1,
				useX5Play: 'true', // 同层播放
				progressbar_enable: 1, // 禁止拖动条
				// rate_array: [10], // 倍速
				width: "100%",
				height: "240",
				parentNode: this.reviewer
			});
		},
		onCCH5PlayerLoaded (error) {
			console.log(123, error)
		},
		on_CCH5player_play () {
			console.log('on_CCH5player_play')
		},
		on_CCH5player_pause () {
			console.log('on_CCH5player_pause')
		},
		on_CCH5player_ended () {
			console.log('on_CCH5player_ended')
			this.$set(this.listvideo[this.isactive],'watchedFlag',1)
			if (this.isactive < 2) {
				this.isactive++;
				// this.loadCCVideo(this.listvideo[this.isactive].videoCcid)
				this.player.changeVideo(this.listvideo[this.isactive].videoCcid);
			}
		},
		onPlayerReady () {
			console.log('onPlayerReady')
		},
		onPlayerVolumeChange () {
			console.log('onPlayerVolumeChange')
		},
	},
}
</script>

<style lang="less" scoped>
.quintessence-wrap{
  width: 100%;
  height: 100vh;
  background-color: #f4f4f4;
  .van-nav-bar {
    background-color: #fca142 !important;
  }
  ::v-deep .van-icon {
    color: #fff;
  }
  ::v-deep .van-ellipsis {
    color: #fff;
  }
  #cc_player{
    width: 100%;
    height: 240px;
    margin-top: 5px;
    padding: 0 5px;
    background-color: #fff;
    div{ 
      width: 100%;
      height: 240px;
      img{
        width: 100%;
        height: 240px;
      }
    }
  }
  .quin-course{
    width: 100%;
    margin-top: 10px;
    background-color: #fff;
    padding: 0 15px 20px;
    .course-list{
      width: 100%;
      height: 60px;
      font-size: 20px;
      font-weight: 800;
      line-height: 60px;
	  letter-spacing: 2px;
    }
    .course-wrap{
      white-space: nowrap;
      overflow-y: hidden;
      overflow-x: scroll;
      &::-webkit-scrollbar{
        display: none;
      }
      .course-box{
        width: 165px;
        height: 80px;
        margin: 10px 0;
        display: inline-block;
        border-radius: 5px;
        box-shadow: 0 0 10px #646566;
        color: #000;
        .course-text{
          // margin-top: 10px;
          height: 30px;
          line-height: 30px;
          font-size: 14px;
          font-weight: 900;
        }
        .course-name{
          height: 50px;
          line-height: 25px;
          font-size: 12px;
          white-space: normal;
          padding-left: 20px;
        }
      }
      .allcolor{
        color: #ff8506;
      }
      :nth-child(1){
        margin: 0 20px;
      }
      :nth-child(2){
        margin-right: 20px;
      }
      :nth-child(3){
        margin-right: 20px;
      }
    }
  }
  
}
.APP02 {
	@colorMain: #2db1ac;
	@colorSecond: #48d8a0;
	.quintessence-wrap {
		.van-nav-bar {
			background-color: @colorMain !important;
		}
		.quin-course {
			.course-box{
				.allcolor{
					color: @colorSecond;
				}
			}
		}
	}
}
.APP03 {
	@colorMain: #1175fe;
	@colorSecond: #3fadfa;
	.quintessence-wrap {
		.van-nav-bar {
			background-color: @colorMain !important;
		}
		.quin-course {
			.course-box{
				.allcolor{
					color: @colorSecond;
				}
			}
		}
	}
}
</style>